import './App.scss';
import React, {lazy, Suspense, useEffect} from "react";
import {Route, Switch} from "react-router-dom";
import {Spinner} from "react-bootstrap";
import {datadogLogs} from "@datadog/browser-logs";

function App() {
    useEffect(() => {
        let isLocal = process.env.NODE_ENV !== 'production';

        datadogLogs.init({
            clientToken: 'pub90ef4ef1086cd066a9b4601ebd15a273',
            forwardErrorsToLogs: !isLocal,
            env: process.env.REACT_APP_ENV,
            service: process.env.REACT_APP_NAME
        });

        if (isLocal) {
            datadogLogs.logger.setHandler('console');
        }
    });
  return (
      <Suspense fallback={<Spinner animation="border" variant="primary" size="xl"/>}>
          <Switch>
              <Route path="/verify/:oid/:token" component={lazy(() => import('./Components/Verify'))} />
              <Route path="/payment/:oid/:token" component={lazy(() => import('./Components/Payment'))} />
              <Route path="/success/:oid/:token/:pid" component={lazy(() => import('./Components/Success'))} />
              <Route path="/denied/:oid/:token/:pid" component={lazy(() => import('./Components/Denied'))} />
              <Route component={lazy(() => import('./Components/NotFound'))} />
          </Switch>
      </Suspense>
  );
}

export default App;
